<template>
  <v-container class="white fill-height">
    <v-row>
      <v-col cols="12" class="mx-auto">
        <v-img
          class="mx-auto ma-4"
          :src="require('@/assets/logo.png')"
          contain
          max-width="300px"
        ></v-img>
      </v-col>
      <v-col cols="12" class="mx-auto text-center">
        <h1>{{ $t("errors.404") }}</h1>
      </v-col>
      <v-col cols="12" class="mx-auto text-center">
        <v-icon size="100" color="error">mdi-close-circle-outline</v-icon>
      </v-col>
      <v-col cols="12" class="mx-auto text-center">
        <h5>{{ $t("errors.404Text") }}</h5>
        <v-btn link primary text @click="goBack()">
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("app.back") }}
        </v-btn>
        <v-btn link primary text @click="goTo">
          <v-icon>mdi-logout</v-icon>
          {{ $t("app.home") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$auth.user();
    }
  },
  methods: {
    goTo() {
      if (this.user.role == 1) {
        this.$router.push({ name: "dashboard" });
      } else {
        this.$router.push({ name: "app-dashboard" });
      }
    }
  }
};
</script>
